
import {
	IonHeader,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Header',
	props: ['pageTitle', 'pageDefaultBackLink'],
	components: {
		IonHeader,
		IonTitle,
		IonToolbar,
		IonButtons,
		IonBackButton,
	},
});

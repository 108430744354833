
import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonRow,
	IonCol,
	IonButton,
	IonIcon,
	IonChip,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { addIcons } from 'ionicons';
import { trash } from 'ionicons/icons';

export default defineComponent({
	name: 'GroupItem',
	emits: ['remove-group'],
	props: ['group'],
	created() {
		addIcons({
			trash,
		});
	},
	methods: {
		showGroup(id: number) {
			this.$router.push(`/group/${id}`);
		},
		removeGroup(id: number) {
			this.$emit('remove-group', id);
		},
	},
	components: {
		IonCard,
		IonCardContent,
		IonCardHeader,
		IonCardTitle,
		IonRow,
		IonCol,
		IonButton,
		IonIcon,
		IonChip,
	},
});


import { defineComponent } from 'vue';
import { IonIcon, IonButton, alertController } from '@ionic/vue';
import { Group } from '@/interfaces';
import GroupItem from '../components/GroupItem.vue';
import { add } from 'ionicons/icons';

export default defineComponent({
	name: 'Home',
	data() {
		return { add };
	},
	computed: {
		groups(): Group[] {
			return this.$store.getters.groups;
		},
	},
	methods: {
		async removeGroup(id: number) {
			const alert = await alertController.create({
				header: 'Ta bort!',
				message: `Vill du ta bort gruppen?`,
				buttons: [
					{
						text: 'Avbryt',
						role: 'cancel',
					},
					{
						text: 'Ja',
						handler: () => {
							this.$store.dispatch('removeGroup', id);
						},
					},
				],
			});
			return alert.present();
		},
	},
	components: {
		GroupItem,
		IonIcon,
		IonButton,
	},
});

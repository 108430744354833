import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")
  const _component_ion_col = _resolveComponent("ion-col")
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_button = _resolveComponent("ion-button")
  const _component_ion_row = _resolveComponent("ion-row")
  const _component_ion_card_header = _resolveComponent("ion-card-header")
  const _component_ion_chip = _resolveComponent("ion-chip")
  const _component_ion_card_content = _resolveComponent("ion-card-content")
  const _component_ion_card = _resolveComponent("ion-card")

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { size: "10" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_card_title, {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showGroup(_ctx.group.id)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.group.name) + " (" + _toDisplayString(_ctx.group.matches.filter((x) => x.games.length).length) + "/" + _toDisplayString(_ctx.group.nrOfMatches) + ") ", 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { size: "2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    fill: "clear",
                    size: "small",
                    color: "dark",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.removeGroup(_ctx.group.id)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        name: "trash",
                        slot: "icon-only"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showGroup(_ctx.group.id)))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.group.players, (player, index) => {
            return (_openBlock(), _createBlock(_component_ion_chip, {
              key: player.id,
              color: index % 2 ? 'primary' : 'dark'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(player.name), 1)
              ]),
              _: 2
            }, 1032, ["color"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_button = _resolveComponent("ion-button")
  const _component_GroupItem = _resolveComponent("GroupItem")
  const _component_base_layout = _resolveComponent("base-layout")

  return (_openBlock(), _createBlock(_component_base_layout, { "page-title": "Pool Resultat" }, {
    "actions-end": _withCtx(() => [
      _createVNode(_component_ion_button, {
        "router-link": "/group/add",
        color: "danger"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "icon-only",
            icon: _ctx.add
          }, null, 8, ["icon"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.groups, (group) => {
          return (_openBlock(), _createBlock(_component_GroupItem, {
            key: group.id,
            group: group,
            onRemoveGroup: _ctx.removeGroup
          }, null, 8, ["group", "onRemoveGroup"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}

import { IonPage, IonContent } from '@ionic/vue';
import Header from './Header.vue';
export default {
	props: ['pageTitle', 'pageDefaultBackLink'],
	components: {
		IonPage,
		IonContent,
		Header,
	},
};

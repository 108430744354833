import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/group/:id',
		component: () => import('../views/GroupDetail.vue'),
	},
	{
		path: '/group/add',
		component: () => import('../views/AddGroup.vue'),
	},
	{
		path: '/group/:id/addMatch',
		component: () => import('../views/AddMatch.vue'),
	},
];

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
});

export default router;
